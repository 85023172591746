@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PattySlaps";
  src: local("PattySlaps"),
  url("./fonts/PattySlaps-Regular.ttf") format("truetype");
  font-weight: normal;
}


@font-face {
  font-family: "Colby";
  src: local("Colby"),
  url("./fonts/Colby-StMed.ttf") format("truetype");
  font-weight: normal;
}


body {
  margin: 0;
  font-family: 'Colby', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dashboard {
  font-family: 'Poppins' !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.patty {
  font-family: "PattySlaps";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-dangerous.ant-btn-primary {
  background-color: #e51212 !important;
}

.quantity-button {
  box-shadow: 10px 20px 25px -10px rgba(0,0,0,1);
  -webkit-box-shadow: 10px 20px 25px -10px rgba(0,0,0,1);
  -moz-box-shadow: 10px 20px 25px -10px rgba(0,0,0,1);
}

.add-to-bag-button {
  box-shadow: 10px 20px 25px -10px rgba(0,0,0,1);
  -webkit-box-shadow: 10px 20px 25px -10px rgba(0,0,0,1);
  -moz-box-shadow: 10px 20px 25px -10px rgba(0,0,0,1);
}

.footer {
  -webkit-box-shadow: inset 0px 30px 15px -22px rgba(0,0,0,0.03);
  box-shadow: inset 0px 30px 15px -22px rgba(0,0,0,0.03);
}

.brand-background-red {
  background-color: #EE2737;
}

.bg-red-brand {
  background-color: #d91727;
}

.brand-border-red {
  border-color: #EE2737;
}

.bg-clip-red {
  clip-path: polygon(3.1% 0%, 96.3% 6%, 98.9% 100%, 0% 93.5%);
}

.tracking-very-tight {
  letter-spacing: -0.08em;
}

.tracking-very-tighter {
  letter-spacing: -0.15em;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.ant-menu.ant-menu-inline-collapsed {
  width: 50px !important;;
}


/* For WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 5px;
}

/* For Firefox 64+ */
html {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

/* For Internet Explorer and Microsoft Edge */
html {
  scrollbar-width: thin;
}

@supports (-ms-overflow-style: none) {
  html {
    -ms-overflow-style: none;
    scrollbar-width: thin;
  }

  html::-ms-scrollbar {
    width: 5px;
  }

  html::-ms-scrollbar-track {
    background-color: #f1f1f1;
  }

  html::-ms-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
}


.ant-card.ant-card-bordered {
  margin: 4px;
  border: 2px solid #9a9a9a !important;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 10px 20px 25px -10px rgba(0,0,0,.1);
  -webkit-box-shadow: 10px 20px 25px -10px rgba(0,0,0,.1);
  -moz-box-shadow: 10px 20px 25px -10px rgba(0,0,0,.1);
}



.react-slidedown > div {
  background-color: black !important;
  padding-left: 10px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  width: 230px !important;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  padding: 10px;
  z-index: 100000;
  overflow: scroll;
}


.text-with-border {
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

.ant-notification{
  z-index: 100000 !important;
}





.ps-menu-button {
  padding-left: 7px !important;
  padding-right: 0px !important;
  margin-right: 5px !important;
  background-color: #131313;
  color: white !important;
  height: 40px !important;
}

.ps-submenu-content {
  padding-left: 10px !important;
  background-color: #575757 !important;

}

.ps-submenu-expand-icon {
  right: 7px !important;
  top: 45% !important;;
}

.ps-menu-button:hover {
  background-color: #000000 !important;
}

.ps-menu-root li {
  background-color: #131313 !important;
}

.ps-menu-root a {
  color: white !important;
}

.ps-sidebar-container {
}

.ps-sidebar-root {
  border: none !important;
}

.brand-h-screen {
  height: calc(100vh - 64px);
}

.ant-card-body{
  padding: 10px !important;
}

.ant-card-body .ant-divider.ant-divider-horizontal {
  margin: 5px 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 13px;
}

.pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 100px;
  font-size: 20px;
}

.pro-menu-item .active {
  font-weight: bold !important;
}


.pro-sidebar .pro-menu .pro-menu-item.active {
  font-weight: bold;
}

li.pro-sub-menu > div > div > ul > div > li.pro-menu-item.active , li.pro-sub-menu > div > div > ul > li.pro-menu-item.active{
  background-color: #2b3073 !important;
  padding-left: 0px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 3px 20px 3px 8px !important;
}


.ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th {
  padding: 6px !important;
  border-right: 1px solid #f1f1f1;
}

.pro-item-content {
  font-family: 'Pattyslaps';
  font-size: 22px;
  margin-bottom: -3px;
  letter-spacing: -.5px;
  font-weight: normal !important;
}

.task .ant-modal-body {
  padding: 10px !important;
}

.task .ant-form-item  {
  margin-bottom: 0px !important;
}

.context-report .ant-table-tbody>tr>td{
  padding: 3px !important;
}

.ant-collapse-content-box {
  padding: 3px !important;
}

.positive-review b{
  color: darkgreen;
  font-size: 16px;
}

.negative-review b{
  color: darkred;
  font-size: 16px;
}

.ant-modal-content .ant-form-item {
  margin-bottom: 5px;
}
.ant-modal-content .ant-form-item-label {
  padding-bottom: 2px;
}

.ant-tabs-tab {
  font-family:"PattySlaps";
  font-size: 22px !important;
  line-height: 20px !important;
  color: #3f3f3f !important;
}

.ant-tabs-tab-active, .ant-tabs-tab-active .ant-tabs-tab-btn {
  background-color: #efefef !important;
  font-weight:bold !important;
  color: black !important;
}

.ant-form-item-label{
  padding-bottom: 2px !important;
}

.employee-form .ant-form-item {
  margin-bottom: 16px !important;
}

.employee-form .ant-form-item label {
  font-weight: bold;
}

.ant-table-content {
  border: 1px solid #e8e8e8;
}

.ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #0f131c !important;
}

.ant-menu {
  background: none !important;
}

.ant-menu-item-selected {
  background: linear-gradient(90deg, #000000 0%, #252525 100%) !important;
  color: white !important;
  border-radius: 0px 1000px 1000px 0px;
}

.ant-menu-item-selected .ant-menu-title-content a, .ant-menu-item-selected .ant-menu-title-content a:hover{
  color: white !important;
}

.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after {
  border-right: none !important;
}

.ant-menu-item-active .ant-menu-submenu-selected, .ant-menu-item-selected:active{
  background: linear-gradient(90deg, #545454 0%, #000000 100%);
  color:black !important;
}

li.ant-menu-submenu-open.ant-menu-submenu-selected {
  background-color: #e8e8e8 !important;
}

.box {
  box-shadow: 0px 4px 10px rgba(77, 77, 77, 0.20);
  background-color:white;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
}

.box-shadow {
  box-shadow: 0px 4px 10px #4d4d4d;
}

.secondary-button {
  background-color: rgb(73, 125, 255) !important;
  background-image: linear-gradient(rgb(91, 118, 255), rgb(54, 114, 245)) !important;
  border-radius: 2px !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px, rgb(74, 104, 253) 0px 0px 1px inset, rgba(255, 255, 255, 0.16) 0px 2px 0px inset !important;
  box-sizing: border-box !important;

  color: white !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}

.primary-button {
  background-color: rgb(73, 125, 255) !important;
  background-image: linear-gradient(rgb(91, 118, 255), rgb(54, 114, 245)) !important;
  border-radius: 2px !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px, rgb(74, 104, 253) 0px 0px 1px inset, rgba(255, 255, 255, 0.16) 0px 2px 0px inset !important;
  box-sizing: border-box !important;

  color: white !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
}

.primary-button:hover {
}

.primary-button:active {

}

.primary-button .icon {
  margin-right: 8px !important;
  display: inline-flex !important;
  align-items: center !important;
}

.ant-menu-submenu-selected {
  color: black !important;
}

/* Apply custom scrollbar to all elements */
*::-webkit-scrollbar {
  width: 6px !important;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 10px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555 !important;
}

* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track colors for Firefox */
}

.faulty-item {
  color: red;
}

.normal-item {
  color: inherit;
}